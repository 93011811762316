import { QueryClientProvider, QueryClient, Hydrate } from "react-query";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { ReactQueryDevtools } from "react-query/devtools";
import { AppProps } from "next/app";
import { ReactNode, useEffect, useState } from "react";
import { SITES } from "utils/constants";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { MS_IN_DAY } from "utils";
import { getGTag } from "utils/analytics";
import { NextUIProvider } from "@nextui-org/react";
import { GrowSurfCampaigns } from "enums/growSurf";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import "react-loading-skeleton/dist/skeleton.css";
import "../styles/globals.css";
import { useRouter } from "next/router";

const CACHE_TIME = MS_IN_DAY * 5; // 5 days

const Noop = ({ children }: { children: ReactNode }) => children;

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: "https://e.getaddify.com",
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") {
        posthog.debug();
      }
    },
  });
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const [gTag, setGTag] = useState("");

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: { queries: { suspense: true, cacheTime: CACHE_TIME } },
      })
  );

  const addGrsfScript = (campaignId: string) => {
    const script = document.createElement("script");
    script.src = "https://app.growsurf.com/growsurf.js?v=2.0.0";
    script.setAttribute("grsf-campaign", campaignId);
    script.async = true;
    document.head.appendChild(script);
  };

  useEffect(() => {
    setGTag(getGTag(window.location.host));

    if (window.location.host.includes(SITES.RENTALS)) {
      addGrsfScript(GrowSurfCampaigns.AddifyApply);
    }
  }, []);

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // @ts-ignore - need to figure out how to add `.provider` to component type
  const ContextProvider = Component.provider || Noop;

  if (typeof window !== "undefined") {
    const localStoragePersistor = createWebStoragePersistor({
      storage: window.localStorage,
    });

    persistQueryClient({
      queryClient,
      persistor: localStoragePersistor,
      maxAge: CACHE_TIME,
      hydrateOptions: {},
      dehydrateOptions: {},
    });
  }

  return (    
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <NextUIProvider>
          <PostHogProvider client={posthog}>
            <ContextProvider>
              {gTag && (
                <GoogleAnalytics
                  strategy="lazyOnload"
                  gaMeasurementId={gTag}
                  trackPageViews
                />
              )}
              <Component {...pageProps} />
            </ContextProvider>
          </PostHogProvider>
        </NextUIProvider>
      </Hydrate>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID!,
  user: {
    anonymous: true,
  },
  options: {
    bootstrap: "localStorage",
    evaluationReasons: true,
  },
  // @ts-ignore: Ts wants FC but above is AppProps
})(MyApp);
